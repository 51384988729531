import styled from 'styled-components'

export const MainContainer = styled.div`
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  position: absolute;
  right: 0;
  width: 50em;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
`;

export const DropdownContainer = styled.div`
  padding: 5px;
  background-color: aliceblue;
  border-radius: 5px;
`;

export const DropdownSelector = styled.a`
  display: flex;
    gap: 5px;
    align-items: center;
    color: black;
`;

export const MainSelector = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid whitesmoke;
  border-radius: 5px;
  padding: 5px;
  gap: 10px;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: aliceblue;
  }
`;