import styled from 'styled-components'

export const MainContainer = styled.div`
  padding: 20px;
`;

export const TopDiv = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const SelectorContainer = styled.div`
	display: flex;
`;

export const Selector = styled.div`
	background-color: #f0fffe;
	padding: 7px;
	border-radius: 5px;
	margin-right: 20px;
`;

export const StatsDiv = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 25px;
`;

export const StatsBox = styled.div`
	width: 19vw;
	height: 12vh;
	padding: 10px;
	border-radius: 5px;
	background-color: white;
`;

export const IconLabelDiv = styled.div`
	display: flex;
	align-items: center;
`;

export const StatsLabel = styled.div`
	margin-left: 10px;
	font-size: 14px;
	color: grey;
`;

export const StatsAmountDiv = styled.div`
	margin-top: 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const AmountLabel = styled.div`
	font-size: large;
`;

export const AmountGraph = styled.div`
	display: flex;
	align-items: center;
	padding: 3px 7px;
	border-radius: 10px;
`;

export const AmountGraphLabel = styled.div`
	color: green;
	margin-left: 5px;
`;


export const GraphDiv = styled.div`
	display: grid;
	grid-template-columns: 60% 2% 38%;
	margin-top: 20px;
	height: 40vh;
`;

export const GraphDivLeft = styled.div`
	background-color: white;
	padding: 15px;
	border-radius: 5px;
	height: 40vh;
`;

export const GraphDivRight = styled.div`
	background-color: white;
	padding: 15px;
	border-radius: 5px;
	height: 40vh;
	flex-direction: column;
	position: relative;
`;

export const GraphDivLabelDiv = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
`;

export const LabelHeading = styled.div`
	font-size: 15px;
`;

export const GraphMonthSelector = styled.div`
	padding: 7px;
	border-radius: 5px;
	border: 1px solid whitesmoke;
	font-size: 12px;
	color: grey;
`;

export const PieDiv = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const PieDivInfo = styled.div`
	margin-top: 7vh;
`;

export const InfoRow = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 15px;
	
`;

export const InfoColor = styled.span`
	width: 15px;
	height: 15px;
	margin-right: 10px;
`;

export const InfoLabel = styled.div`
	font-size: 13px;
`;

export const PieDivBottom = styled.div`
	width: 100%;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	background-color: aliceblue;
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 5px 15px;
	display: flex;
	justify-content: space-between;
	align-items:center;
`;

export const PieBottomButton = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	border: 1px solid #cfcaca;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
    color: darkslategrey;
`;

export const TotalAmount = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;


export const DataReportDiv = styled.div`
	margin-top: 20px;
	background-color: white;
	border-radius: 5px;
	padding: 20px;
`;

export const ReportDivHeading = styled.div`
	font-size: 15px;
	padding: 5px 0px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;


export const PopoverMenu = styled.div`
	padding: 5px;
	margin: 0;
	width: 100%;

	&:hover {
		background-color: #f0fffe;
		cursor: pointer;
	}
`;

