import styled from 'styled-components'

export const MainContainer = styled.div`
  padding: 20px;
`;


export const TopHeader = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const Selector = styled.div`
	background-color: #f0fffe;
	padding: 7px;
	border-radius: 5px;
	width: fit-content;
`;

export const BodyContainer = styled.div`
	// margin-top: 30px;
	display: flex;
	flex-wrap: wrap;
	padding: 30px;
`;

export const BuddyCard = styled.div`
	background-color: white;
	border-radius: 5px;
	width: 250px;
	height: 410px;
	margin-top: 25px;
	padding: 15px;
	margin-right: 30px;
	position: relative;
	overflowY: auto;
`;
export const DownloadBottom = styled.div`
	width: 100%;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	background-color: #f0f2f5;

	bottom: 0;
	left: 0;
	padding: 5px 15px;
	display: flex;
	justify-content: right;
	align-items:center;
`;

export const DownloadBottomButton = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	border: 1px solid #cfcaca;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
    background-color: aliceblue;
    color: darkslategrey;
`;
