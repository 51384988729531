import styled from 'styled-components'

export const MainContainer = styled.div`
  display: grid;
  grid-template-columns:0% 100% 0%;
  padding: 30px;

`;

export const CenterContainer = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  background-color: white;
  padding: 10px;
  width: 100%;
  border-radius: 7px;
  display:flex;
  justify-content:space-between;
  font-size: large;
`;

export const Body = styled.div`
  margin-top: 10px;
  background-color: white;
  border-radius: 7px;
  padding: 15px;
`;

export const Button = styled.button`
  margin: 10px 10px 10px 0px;
  background-color: #0D6EFE;
  color:white;
  font-weight:900;
  padding: 2px;
  border-radius: 4px;
  width: 200px;
  margin-left: 33em;
`;
export const SelectorContainer = styled.div`
  display: flex;
`;

export const Selector = styled.div`
  background-color: #f0fffe;
  padding: 7px;
  border-radius: 5px;
  margin-right: 20px;
`;