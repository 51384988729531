import React from 'react';

export default function Spinner(){
  return(
    <div style = {{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10%'}}>
      <img src="https://www.image.sporthood.in/videos/spinner.gif" alt="loading..." style={{width: '250px'}}  />
    </div>
  )
}

