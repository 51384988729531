import React, {useState, useEffect} from "react";
import {Routes, Route, useNavigate} from 'react-router-dom';
import moment from 'moment';
import { Dropdown, Menu, Space, Divider, Table, Tag, Select, Empty, DatePicker, Spin, Tooltip as AntTooltip, Drawer, Segmented, Input, Tabs, Button, Comment, Form, Avatar, List } from 'antd';
import { DownOutlined, DollarTwoTone, RiseOutlined, FallOutlined, MoreOutlined, CloseCircleOutlined, AppstoreOutlined, BarsOutlined, EditOutlined, FolderViewOutlined } from '@ant-design/icons';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Sector, Cell } from 'recharts';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import * as Styled from './style.js';
import { postData,getData } from '../../config/api-connect';
import Spinner from '../../components/spinner'

const { Option } = Select;
const { TextArea } = Input;


const COLORS = ['#0088FE', '#FFBB28', '#FF8042'];

const menu = (
  <Menu
    items={[
      {
        label: '1st menu item',
        key: '0',
      },
      {
        label: '2nd menu item',
        key: '1',
      },
      {
        type: 'divider',
      },
      {
        label: '3rd menu item',
        key: '3',
      },
    ]}
  />
);

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} placeholder = "start typing ..." />
    </Form.Item>
    <Form.Item>
      <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
        Add Comment
      </Button>
    </Form.Item>
  </>
);


const Overview = () => {

  var today = new Date();
  var mm = String(today.getMonth() + 1).padStart(2, '0')
  var local_month= JSON.parse(localStorage.getItem('month'));
  if(local_month){
   var mm=local_month
  }else{
      var mm = String(today.getMonth() + 1).padStart(2, '0')
  }


  var yyyy = today.getFullYear()
  yyyy = yyyy.toString()


  var local_type = JSON.parse(localStorage.getItem('type'))
  if (!local_type){
    local_type="0"
  }

  var local_selectedId = JSON.parse(localStorage.getItem('selectedId'))
  if (!local_selectedId){
    local_selectedId="All"
  }
  if (local_type == 2 && local_selectedId =='All'){
    local_type="0"
  }

  var local_selectedVenueId = JSON.parse(localStorage.getItem('selectedVenueId'))
   if (local_type ==1 &&  local_selectedVenueId !=''){
    local_type="2"
    local_selectedVenueId=local_selectedId
  }

  const [loading, setLoading] = useState(true)
  const [type, setType] = useState(local_type)
  const [cities, setCities] = useState([])
  const [venues, setVenues] = useState([])
  const [data, setData] = useState([])
  const [selectedId, setSelectedId] = useState(local_selectedId)
  const [month, setMonth] = useState(mm)
  const [year, setYear] = useState(yyyy)
  const [revenueSum, setRevenueSum] = useState('')
  const [collectionSum, setCollectionSum] = useState('')
  const [expenseSum, setExpenseSum] = useState('')
  const [profitSum, setProfitSum] = useState('')
  const [revenuePercent, setRevenuePercent] = useState('')
  const [collectionPercent, setCollectionPercent] = useState('')
  const [expensePercent, setExpensePercent] = useState('')
  const [profitPercent, setProfitPercent] = useState('')
  const [shareData, setShareData] = useState([])
  const [detailedShareData, setDetailedShareData] = useState([])
  const [graphData, setGraphData] = useState({})
  const [pieGraphData,setPieGraphData] = useState([])
  const [open, setOpen] = useState(false)
  const [rowId, setRowId] = useState('')
  const [comments, setComments] = useState([]);
  const [submitting, setSubmitting] = useState(false)
  const [value, setValue] = useState('');
  const [venuelist,setVenuelist]=useState([]);
  const[selectedVenueId,setSelectedVenueId]=useState(local_selectedVenueId)

  

  const CommentList = ({ comments }) => (
    <List
      dataSource = {comments}
      header = {' '}
      itemLayout = "horizontal"
      renderItem = {(props) => <Comment {...props} />}
    />
  );

  const handleSubmitComment = (id) => {
    if (!value) return;
    setSubmitting(true);
    addShareComments(id)
  }

  const handleChangeComment = (e) => {
    setValue(e.target.value);
  };
  
  useEffect(() => {
     localStorage.setItem('month', JSON.stringify(month));
     localStorage.setItem('year', JSON.stringify(year));
     localStorage.setItem('selectedId', JSON.stringify(selectedId));
     localStorage.setItem('type', JSON.stringify(type));
     localStorage.setItem('selectedVenueId',JSON.stringify(selectedVenueId))

  }, [month,year,selectedId,type,selectedVenueId]);


  useEffect(() => {
     getCitiesVenues()
  }, []);
  useEffect(() => {
    if (local_selectedId==selectedId){
     setSelectedVenueId(local_selectedVenueId)
    }
    else{
      setSelectedVenueId('')
    }
  }, [selectedId]);

  useEffect(() => {
   

    setLoading(true)
    getExpenseGraphData()
    getFinanceDashboardStats()
    getCollectionRevenueForGraph()
    getContractShares()
    if (type==1){
    getVenuesInCity()}

  },[selectedId, month, year,selectedVenueId]);

  useEffect(() => {
    setLoading(true)
    getTypeUpdate(type)
    getVenuesInCity()

  }, [cities,venues]);


  const showDrawer = (id) => {
    setOpen(true);
    setRowId(id)
  };

  const onClose = () => {
    setOpen(false);
  };

const getTypeUpdate=(value)=>{

  var temp = JSON.parse(localStorage.getItem('selectedId'))
  
  if(value == "0"){
      setData([{"id": "0", "name": "All"}])
      setSelectedId("All")
    }
    if(value == "1"){
      setData(cities)
      if(cities[0] && (temp == 'All' || temp.length > 4)){
        setSelectedId(cities[0].id)
      }
     
    }
    if(value == "2" ){
      setData(venues)
      if(venues[0] && (temp == 'All' || temp.toString().length <= 4)){
        setSelectedId(venues[0].id)
      }
    }

    setLoading(false)

  }

const table_columns = [
  {
    title: 'Venue',
    dataIndex: 'venue_name',
    key: 'venue',
  },
  {
    title: 'Sporthood Share',
    dataIndex: 'sh_share_sum',
    key: 'sh_share',
    render: (item,record) => <span><span style={{float:'left'}}> {record.sh_share_sum.toLocaleString('en-IN') + ' + '+ record.sh_share_gst_sum.toLocaleString('en-IN') + ' GST' }</span><a style={{ float:'center'}} >= </a><a className="shareView" >{(record.sh_share_sum + record.sh_share_gst_sum).toLocaleString('en-IN')}</a></span>
  },
  {
    title: 'Venue Share',
    dataIndex: 'venue_share_sum',
    key: 'venue_share',
    render: (item,record) => <span><span> {record.venue_share_sum.toLocaleString('en-IN') + ' + ' + record.venue_share_gst_sum.toLocaleString('en-IN') + ' GST' }</span><a style={{ float:'center'}} >= </a> <a className="shareView">{ (record.venue_share_sum + record.venue_share_gst_sum).toLocaleString('en-IN')} </a></span>
  },
  {
    title: 'Technical Fee',
    dataIndex: 'technical_fee_sum',
    key: 'technical_fee',
    render: (item,record) =><span> <span> {record.technical_fee_sum.toLocaleString('en-IN') + ' + ' + record.technical_fee_gst_sum.toLocaleString('en-IN') + ' GST' + ' = '}</span> <a  className="shareView">{(record.technical_fee_sum + record.technical_fee_gst_sum).toLocaleString('en-IN')} </a></span>
  },
  {
    title: '',
    key: 'actions',
    render: (item)=>  <AntTooltip title = "More"> <MoreOutlined style={{cursor: 'pointer'}} onClick = {()=>showDrawer(item.venue_id)}/> </AntTooltip>
  },
];

  const getCitiesVenues = async () => {
    await getData('/finance/get_cities_and_venues')
    .then(response =>{
      setCities(response.cities)
      setVenues(response.venues)
    })
    .catch(error => {
      setLoading(false)
      console.log(error.message);
    });
  }
  
  const getVenuesInCity =() =>{
    if (type == 1 ){

      setVenuelist( venues.filter((item)=>{
        return item.city===selectedId
       }))
        
      }
    }
  
  const getFinanceDashboardStats = async () => {
    await postData('/finance/get_finance_dashboard_stats/', {'type': type, "id": selectedId, "year": year, "month": month,"venueId":selectedVenueId})
    .then(response =>{
      setRevenueSum(response.revenue_sum)
      setCollectionSum(response.collection_sum)
      setExpenseSum(response.expense_sum)
      setProfitSum(response.profit_sum)
      setRevenuePercent(response.percent_diff_revenue)
      setCollectionPercent(response.percent_diff_collection)
      setExpensePercent(response.percent_diff_expense)
      setProfitPercent(response.percent_diff_profit)
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error.message);
    });
  }

  const getContractShares = async () => {
    await postData('/finance/get_contract_shares/', {'type': type, "id": selectedId, "year": year, "month": month,"venueId":selectedVenueId})
    .then(response =>{
      setShareData(response.result)
      setDetailedShareData(response.detailed_shares)
      setComments(response.comment)
    })
    .catch(error => {
      setLoading(false)
      console.log(error.message);
    });
  }

  const getCollectionRevenueForGraph = async () => {
    await postData('/finance/get_collection_revenue_for_graph/', {'type': type, "id": selectedId, "year": year, "month": month,"venueId":selectedVenueId})
    .then(response =>{
      setLoading(false)
      setGraphData(response.result)
    })
    .catch(error => {
      setLoading(false)
      console.log(error.message);
    });
  }

  const getExpenseGraphData = async () => {
    await postData('/finance/get_expense_graph_data/', {'type': type, "id": selectedId, "year": year, "month": month,"venueId":selectedVenueId})
    .then(response =>{
      setLoading(false)
      setPieGraphData(response.result)
    })
    .catch(error => {
      setLoading(false)
      console.log(error.message);
    });
  }

  const addShareComments = async (id) => {
    await postData('/finance/add_share_comment/', {'venue_id': id, "comment": value, "year": year, "month": month,"venueId":selectedVenueId})
    .then(response =>{
      setSubmitting(false)
      setValue('')
      getContractShares()
    })
    .catch(error => {
      setSubmitting(false)
      console.log(error.message);
    });
  }

  const onChangeType = (value) => {
    setType(value)
    getTypeUpdate(value)
  }

  const onChangeSelector = (value) => {
    setSelectedId(value)
    setSelectedVenueId(value)
  }

  const type_menu = (
    <Menu
      selectable
      defaultSelectedKeys={['0']}
      items={[
        {
          key: '0',
          label: <span onClick = {()=>onChangeType("0", 'All')}> All </span>,
        },
        {
          key: '1',
          label: <span onClick = {()=>onChangeType("1", 'City')}> City </span>,
        },
        {
          key: '2',
          label: <span onClick = {()=>onChangeType("2", 'Venue')}> Venue </span>,
        },
      ]}
    />
  );

  const replaceCharacter = (value) => {
    if(Math.sign(value) == '-1'){
      return value.toString().replace('-','')
    }
    else{
      return value
    }
  }

  const onChangeYear = (date, dateString) => {
    setYear(dateString);
  };
   const navigate = useNavigate();
     const navigateToExpenseDetail = () => {
    navigate('/expenseDetails',{state : {type:type,id:selectedId,month:month,year:year,venue_id:selectedVenueId}});
  };

  const onChange = (key) => {
    console.log(key);
  };


  

  return (
    <Spin spinning={loading} indicator={<Spinner />}>
      <Styled.MainContainer>

      <Drawer placement="bottom"  onClose={onClose} visible={open} closable={false} height = {400}>
        
      <Tabs>
        <Tabs.TabPane tab="Detailed View" key="item-1">
          {
            detailedShareData &&
            detailedShareData.map(item=>(
              item.venue == rowId &&
                <div style={{marginTop: '15px'}}>
                  <b> {item.gametype} </b>
                  <div style={{marginTop: '10px'}}>
                    <div style={{display: 'grid', gridTemplateColumns: '10% 2% 40%'}}>
                      <span> Sporthood Share </span> 
                      <span> : </span> 
                      <span> {item.sh_share.share.toLocaleString('en-IN')} + {item.sh_share.gst.toLocaleString('en-IN')} GST = {(item.sh_share.share + item.sh_share.gst).toLocaleString('en-IN')} </span>
                    </div>
                    <div style={{display: 'grid', gridTemplateColumns: '10% 2% 40%', marginTop: '5px'}}>
                      <span> Venue Share </span> 
                      <span> : </span> 
                      <span> {item.venue_share.share.toLocaleString('en-IN')} + {item.venue_share.gst.toLocaleString('en-IN')} GST = {(item.venue_share.share + item.venue_share.gst).toLocaleString('en-IN')} </span>
                    </div>
                    <div style={{display: 'grid', gridTemplateColumns: '10% 2% 40%', marginTop: '5px'}}>
                      <span> Technical Fee </span> 
                      <span> : </span> 
                      <span> {item.technical_fee.share.toLocaleString('en-IN')} + {item.technical_fee.gst.toLocaleString('en-IN')} GST = {(item.technical_fee.share + item.technical_fee.gst).toLocaleString('en-IN')} </span>
                    </div>
                  </div>

                </div>
            ))
          }

        </Tabs.TabPane>
        <Tabs.TabPane tab="Comments" key="item-2">
          {
            comments &&
              comments.map(item=>(
                <Comment
                  avatar={<Avatar src={item.created_by.profile_pic} alt="Han Solo" />}
                  author={item.created_by.name}
                  datetime={moment(item.created_on).fromNow()}
                  content={item.comment}
                />
              ))
          }

          <Editor
            onChange={handleChangeComment}
            onSubmit={()=>handleSubmitComment(rowId)}
            submitting={submitting}
            value={value}
          />


        </Tabs.TabPane>

        <Tabs.TabPane tab="Report" key="item-3">
          <Empty style={{marginTop: '5%'}} description = {<span style={{color: 'grey', fontSize: 'small'}}> report not available </span>} />
        </Tabs.TabPane>


      </Tabs>
        
        
      </Drawer>

        <Styled.TopDiv>
          
          <Styled.SelectorContainer>

            <Styled.Selector>
              <Select
                style={{
                  width: 100,
                }}
                onChange={onChangeType}
                value = {type}
                bordered={false}
              >
                <Option value="0"> All </Option>
                <Option value="1" > City </Option>
                <Option value="2"> Venue </Option>
              </Select>
            </Styled.Selector>

            <Styled.Selector>
              <Select
                style={{
                  // width: 400,
                  // width: 170
                  width: type == "0" ? 100 : type == "1" ? 170 : 400
                }}
                showSearch = {type != "0"}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) => option.name.toLowerCase().includes(input.toLowerCase())}
                // onClick={()=>getVenuesInCity(selectedId)}
                onChange={setSelectedId}
                value = {selectedId}
                bordered={false}
              >

              {
                data.map(item=>(
                  <Option value={item.id} key={item.id} name = {item.name}> {item.name} </Option>    
                ))
              }

              </Select>
            </Styled.Selector>
            { type ==1 &&

            <Styled.Selector>
              <Select
                style={{
                  width: 400,
                }}
                showSearch = {type == "1"}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) => option.name.toLowerCase().includes(input.toLowerCase())}
                onChange={setSelectedVenueId}
                value = {selectedVenueId}
                bordered={false}
              >
                <Option value=""> All </Option>

                {venuelist.map(item=>(

                  <Option value={item.id} key={item.id} name = {item.name}> {item.name} </Option>    
                ))
              }
              </Select>
            </Styled.Selector>
          }
            
          </Styled.SelectorContainer>

          <div style={{display: 'flex'}}>
            <Styled.Selector>
              <Select
                  style={{
                    width: 'auto',
                  }}
                  onChange={setMonth}
                  value = {month}
                  bordered={false}
                >
                  <Option value="12"> December </Option>
                  <Option value="11"> November </Option>
                  <Option value="10"> October </Option>
                  <Option value="09"> September </Option>
                  <Option value="08"> August </Option>
                  <Option value="07"> July </Option>
                  <Option value="06"> June </Option>
                  <Option value="05"> May </Option>
                  <Option value="04"> April </Option>
                  <Option value="03"> March </Option>
                  <Option value="02"> February </Option>
                  <Option value="01"> January </Option>
                </Select>
            </Styled.Selector>

            <Styled.Selector>
              <DatePicker defaultValue={moment(year, 'YYYY')}  onChange = {onChangeYear}  bordered={false}  picker="year" />
            </Styled.Selector>
          </div>

        </Styled.TopDiv>

        <Styled.StatsDiv>

          <Styled.StatsBox>
            <Styled.IconLabelDiv>
              <DollarTwoTone twoToneColor="#c48e1a" style={{fontSize: 'large'}} />
              <Styled.StatsLabel> Revenue </Styled.StatsLabel>
            </Styled.IconLabelDiv>

            <Styled.StatsAmountDiv>
              <Styled.AmountLabel>
                {
                  (!revenuePercent &&  revenuePercent != 0)?
                    <span style={{color: 'grey', fontSize: 'small'}}> data not available </span>
                  :
                    revenueSum.toLocaleString('en-IN')
                }
              </Styled.AmountLabel>
              {
                (revenuePercent || revenuePercent == 0) &&
                  <Styled.AmountGraph style={{backgroundColor: Math.sign(revenuePercent) == '1' ? 'rgb(233 255 232)' : 'rgb(255 232 232)'}}>
                    {
                      Math.sign(revenuePercent) == '1' ?
                        <RiseOutlined  style={{color:  'green'}}/> 
                      :
                        <FallOutlined  style={{color: 'red'}}/>
                    }
                    <Styled.AmountGraphLabel style={{color: Math.sign(revenuePercent) == '1' ? 'green' : 'red'}}> {replaceCharacter(revenuePercent) + '%'} </Styled.AmountGraphLabel>
                  </Styled.AmountGraph>
              }
            </Styled.StatsAmountDiv>
          </Styled.StatsBox>

          <Styled.StatsBox>
            <Styled.IconLabelDiv>
              <DollarTwoTone twoToneColor="#1ab9c4" style={{fontSize: 'large'}} />
              <Styled.StatsLabel> Collection </Styled.StatsLabel>
            </Styled.IconLabelDiv>

            <Styled.StatsAmountDiv>
              <Styled.AmountLabel> 
                {
                  (!collectionPercent &&  collectionPercent != 0)?
                    <span style={{color: 'grey', fontSize: 'small'}}> data not available </span>
                  :
                    collectionSum.toLocaleString('en-IN')
                }  
              </Styled.AmountLabel>
              {
                (collectionPercent || collectionPercent == 0) &&
                  <Styled.AmountGraph style={{backgroundColor: Math.sign(collectionPercent) == '1' ? 'rgb(233 255 232)' : 'rgb(255 232 232)'}}>
                    {
                      Math.sign(collectionPercent) == '1' ?
                        <RiseOutlined  style={{color:  'green'}}/> 
                      :
                        <FallOutlined  style={{color: 'red'}}/>
                    }
                    <Styled.AmountGraphLabel style={{color: Math.sign(collectionPercent) == '1' ? 'green' : 'red'}}>
                        {replaceCharacter(collectionPercent) + '%'}
                     </Styled.AmountGraphLabel>
                  </Styled.AmountGraph>
              }
              
            </Styled.StatsAmountDiv>
          </Styled.StatsBox>

          <Styled.StatsBox>
            <Styled.IconLabelDiv>
              <DollarTwoTone twoToneColor="#831ac4" style={{fontSize: 'large'}} />
              <Styled.StatsLabel> Expense </Styled.StatsLabel>
            </Styled.IconLabelDiv>

            <Styled.StatsAmountDiv>
              <Styled.AmountLabel> 
                {
                  (!expenseSum)?
                    <span style={{color: 'grey', fontSize: 'small'}}> data not available </span>
                  :
                    expenseSum.toLocaleString('en-IN')
                }  
              </Styled.AmountLabel>
              {
                (expensePercent || expensePercent == 0) &&
                  <Styled.AmountGraph style={{backgroundColor: Math.sign(expensePercent) == '1' ? 'rgb(233 255 232)' : 'rgb(255 232 232)'}}>
                    {
                      Math.sign(expensePercent) == '1' ?
                        <RiseOutlined  style={{color:  'green'}}/> 
                      :
                        <FallOutlined  style={{color: 'red'}}/>
                    }
                    <Styled.AmountGraphLabel style={{color: Math.sign(expensePercent) == '1' ? 'green' : 'red'}}>
                        {replaceCharacter(expensePercent) + '%'}
                     </Styled.AmountGraphLabel>
                  </Styled.AmountGraph>
              }
              
            </Styled.StatsAmountDiv>

          </Styled.StatsBox>

          <Styled.StatsBox>
            <Styled.IconLabelDiv>
              <DollarTwoTone twoToneColor="#1a47c4" style={{fontSize: 'large'}} />
              <Styled.StatsLabel> Profit </Styled.StatsLabel>
            </Styled.IconLabelDiv>

            <Styled.StatsAmountDiv>
              <Styled.AmountLabel> 
                {
                  (!profitSum)?
                    <span style={{color: 'grey', fontSize: 'small'}}> data not available </span>
                  :
                    profitSum.toLocaleString('en-IN')
                }  
              </Styled.AmountLabel>
              {
                (profitPercent || profitPercent == 0) &&
                  <Styled.AmountGraph style={{backgroundColor: Math.sign(profitPercent) == '1' ? 'rgb(233 255 232)' : 'rgb(255 232 232)'}}>
                    {
                      Math.sign(profitPercent) == '1' ?
                        <RiseOutlined  style={{color:  'green'}}/> 
                      :
                        <FallOutlined  style={{color: 'red'}}/>
                    }
                    <Styled.AmountGraphLabel style={{color: Math.sign(profitPercent) == '1' ? 'green' : 'red'}}>
                        {replaceCharacter(profitPercent) + '%'}
                     </Styled.AmountGraphLabel>
                  </Styled.AmountGraph>
              }
              
            </Styled.StatsAmountDiv>

          </Styled.StatsBox>
        </Styled.StatsDiv>

        <Styled.GraphDiv>
          
          <Styled.GraphDivLeft>
            <Styled.GraphDivLabelDiv>
              <Styled.LabelHeading> Revenue - Collection - Profit Analysis </Styled.LabelHeading>

              <Styled.GraphMonthSelector>
                {/*
                  month == '09' ? 'September' : month == '08' ? 'August' : month =='07' ? 'July' : 'June'
                */}
                Last 6 months

                {/*<Dropdown overlay={menu} trigger={['click']} placement="topLeft">
                  <a onClick={(e) => e.preventDefault()} style={{color: 'grey'}}>
                    <Space>
                      Last 6 months
                      <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>      */}
              </Styled.GraphMonthSelector>
            
            </Styled.GraphDivLabelDiv>

            <ResponsiveContainer width="100%" height="80%">
              <LineChart
                width={500}
                height={300}
                data={graphData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="revenue" stroke="#c48e1a" />
                <Line type="monotone" dataKey="collection" stroke="#1a47c4" />
                <Line type="monotone" dataKey="profit" stroke="rgb(30 124 10)" />
              </LineChart>
            </ResponsiveContainer>
          </Styled.GraphDivLeft>
          <span></span>

          <Styled.GraphDivRight style={{alignItem: 'center'}}>

            <Styled.GraphDivLabelDiv>
              <Styled.LabelHeading> Expense Chart </Styled.LabelHeading>

             {/* <Styled.GraphMonthSelector>
                {
                  month  
                }
              </Styled.GraphMonthSelector>
            */}
            </Styled.GraphDivLabelDiv>

            {/**/}

            {
              pieGraphData.length > 0 &&
              !(pieGraphData[0]['value'] == 0 && pieGraphData[1]['value'] == 0 && pieGraphData[2]['value'] == 0) ? 
              
                            <Styled.PieDiv>
                              
                              <div style={{position: 'relative'}}>
                              <PieChart width={200} height={200}>
                                <Pie
                                  data={pieGraphData}
                                  innerRadius={60}
                                  // outerRadius={80}
                                  fill="#8884d8"
                                  paddingAngle={5}
                                  dataKey="value"
                                >
                                  {pieGraphData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                  ))}
                                </Pie>
                              </PieChart>
                              {
                                pieGraphData &&
                                  <Styled.TotalAmount> {(pieGraphData[0]['value'] + pieGraphData[1]['value'] + pieGraphData[2]['value']).toLocaleString('en-IN')} </Styled.TotalAmount>
                              }
                              </div>
                              
              
                              <Styled.PieDivInfo>
                                {
                                  pieGraphData.map((item,index)=>(
                                    <Styled.InfoRow>
                                      <Styled.InfoColor style={{backgroundColor: index == 0 ? '#0088FE' : index == 1 ? '#FFBB28' : '#FF8042'}} />
                                      <Styled.InfoLabel> {item.name} - {item.value.toLocaleString('en-IN')}</Styled.InfoLabel>
                                    </Styled.InfoRow>
                                  ))
                                }
              
                              </Styled.PieDivInfo>
              
                              </Styled.PieDiv>

              :
               <Empty style={{marginTop: '10%'}} description = {<span style={{color: 'grey', fontSize: 'small'}}> data not available </span>} />
            }
            
            

            <Styled.PieDivBottom>
              <Styled.PieBottomButton> Download </Styled.PieBottomButton>
              <Styled.PieBottomButton onClick={navigateToExpenseDetail}> View Details </Styled.PieBottomButton>
            </Styled.PieDivBottom>


          </Styled.GraphDivRight>

        </Styled.GraphDiv>


        <Styled.DataReportDiv>
          <Styled.ReportDivHeading> 
            Settlement Reports 
            <Styled.PieBottomButton style={{backgroundColor: 'aliceblue'}}> Download </Styled.PieBottomButton>
          </Styled.ReportDivHeading>

          <Table columns={table_columns} dataSource={shareData} style={{marginTop: '10px', margin: '10px'}} />

        </Styled.DataReportDiv>      

      </Styled.MainContainer>
    </Spin>
  );
}

export default Overview;