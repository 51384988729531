import React from "react";
import { Button, Result } from 'antd';

function NotFound(){
  return(

      <div className="not-found-container">
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
        />
      </div>
      )
}

export default NotFound