import React, {useState, useEffect} from "react";
import { useNavigate,useLocation } from "react-router-dom";
import { Button, message, Steps, AutoComplete, Col, Row,Typography,Tooltip, Select, Input, InputNumber, DatePicker,Radio, Collapse, Upload, Popover, Empty, Alert, Modal } from 'antd';
import { PlusCircleOutlined, ArrowRightOutlined, ArrowLeftOutlined, DeleteOutlined, InboxOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { postData,getData } from '../../config/api-connect';
import * as Styled from './style.js';
import { FaAngleDoubleLeft } from "react-icons/fa";

const { Step } = Steps;
const { Option } = Select;
const { Dragger } = Upload;
const { Panel } = Collapse;
const { Text, Link } = Typography;
const { TextArea } = Input;
const { Optionn } = AutoComplete;



  

export default function ContractTermAddition(){

  const [current, setCurrent] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  
  const [addStatus,setAddstatus]=useState(false)
  
  const [margin, setMargin] = useState();
  const [criteria, setCriteria] = useState(1);
  const [criteriaType, setCriteriaType] = useState(0);
  const [criteriaCount, setCriteriaCount] = useState(1);

  const [technicalFee,setTechnicalFee] = useState(false);
  const [flatPercent,setFlatPercent] = useState(false);

  const [filteredBuddies, setFilteredBuddies] = useState([])
  const [filteredPrograms, setFilteredPrograms] = useState([])

  const [receivedVenues, setReceivedVenues] = useState([])
  const [receivedBuddies, setReceivedBuddies] = useState([])
  
  const [selectedVenueId, setSelectedVenueId] = useState('')
  const [selectedBuddies, setSelectedBuddies] = useState('')
  const [venueData, setVenueData] = useState('')
  const [ifsc, setIfsc] = useState('')

  const [filteredData,setFilteredData]=useState();

  const [contractDate, setContractDate] = useState('')
  const [periodFrom, setperiodFrom] = useState('')
  const [periodTo, setperiodTo] = useState('')
  const [tenure, setTenure] = useState('')
  const [basis, setBasis] = useState();
  const [gst, setGst] = useState();

  const [fileList, setFileList] = useState([]);
  const [comments, setComments] = useState([])
  
  const [contractTerms, setContractTerms] = useState([])
  
  const navigate = useNavigate();
   const {state} = useLocation();

  // const Redirect = () =>{
  //     <Navigate to="/contractInfo/" />
  //     navigate('/contractInfo');

  //   }

 

  const successModal = (message) => {
    Modal.success({
      title: message,
      onOk:()=>{navigate('/contractInfo')}
    });
    
  };
 const navigateToVeiw=()=>{
  navigate('/contractInfo')
 }
  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },

    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },

    fileList,

      onChange(info) {
        if (info.file.status !== 'uploading') {
        }

        if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },

      progress: {
        strokeColor: {
          '0%': '#108ee9',
          '100%': '#87d068',
        },
        strokeWidth: 3,
        format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
      },
  };


  const terms_fields = {
    "program": '',
    "type": 0,
    "venue_share": '',
    "sporthood_share": '',
    "criteria": []
  }

  const {id}=state

  const setData = (data) => {
      const filterdata = data.filter((item)=> item.id == selectedVenueId)
      // await setFilteredBuddies(filterdata[0]['tagged_buddies'])
       setFilteredPrograms(filterdata[0]['available_games'])
     
  } 

  const addTermsData = (index, name, value) => {
    var contract_term = contractTerms.filter((item,i) => i == index)
    contract_term = contract_term[0]
    contract_term[name] = value
  }

  const addNewCriteriaData = (index, term_index, name, value) => {
    var contract_term = contractTerms.filter((item,i) => i == term_index)
    contract_term = contract_term[0]
    var criterias = contract_term['criteria']
    criterias[index][name] = value
  }

  const getDetailsForContractAdding = async () => {
    await getData('/finance/get_details_for_contract_creation')
    .then(response =>{
      setReceivedVenues(response.result)
        setData(response.result)
    })
    .catch(error => {
      alert(error.message);
    });
  }

 useEffect(() => {
     forContract()
     if(selectedVenueId){
      getDetailsForContractAdding()
     }
  }, [selectedVenueId]);





    const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };



  const handleChangeProgram = (index,value) => {
    addTermsData(index, 'program', value)
    setFilteredData(value)
  };
  const onChangeMargin = (e,index) => {
    setMargin(e.target.value);
    addTermsData(index, 'type', e.target.value)
  };
  
  const handleChangeGame = (value) => {
    var x=0
  };
  
  const onChangeVenueShare = (index, value, type, criteria_index = null) => {
    if(type == 'term'){
      addTermsData(index, 'venue_share', value)
    }
    else{
      addNewCriteriaData(criteria_index, index, 'venue_share', value)
    }
  };

  const onChangeShShare = (index, value, type, criteria_index = null) => {
    if(type == 'term'){
      addTermsData(index, 'sporthood_share', value)
    }
    else{
      addNewCriteriaData(criteria_index, index, 'sporthood_share', value)
    }
  };
  

  const onChangeCriteriaType = (value, index, term_index) => {
    setCriteriaType(value);
    addNewCriteriaData(index, term_index, 'criteria_type', value)
  };
  
  const onChangeValueFrom = (index, term_index, value) => {
    addNewCriteriaData(index, term_index, 'value_from', value)
  };

  const onChangeValueTo = (index, term_index, value) => {
    addNewCriteriaData(index, term_index, 'value_to', value)
  };

  const onChangeVenue = (id) => {
    setSelectedVenueId(id)
  }

   const onChangeTechnicalFee=(e) => {
    setTechnicalFee(e.target.value);
  }
  const onChangeFlatPercent = (e) => {
    setFlatPercent(e.target.value)
  };

  const removeTerm = async (index) => {
    setContractTerms(contractTerms.filter((item,i) => index != i));
  }

  const removeCriteria = async (index,term_index) => {
    var contract_term = contractTerms.filter((item,i) => term_index == i)
    contract_term[0]['criteria'] = contract_term[0]['criteria'].filter((val,ind) => index != ind)

    setContractTerms(contract_term);
  }

  const genExtra = (index,type, main_index=null) => (
    type == 'term' ?
      <DeleteOutlined
        onClick={() => removeTerm(index)}
      />
    :
      <DeleteOutlined
        onClick={() => removeCriteria(index,main_index)}
      />

    );


  const addNewTerm =  async() => {
    setAddstatus(true)
     let new_contract_term = JSON.parse(JSON.stringify(terms_fields))
     let contract_terms_deep_copy = JSON.parse(JSON.stringify(contractTerms))
     contract_terms_deep_copy.push(new_contract_term)
     await setContractTerms(contract_terms_deep_copy)
  }

  const addNewCriteria = async(term_index) => {
    var contract_term = contractTerms.filter((item,index) => index == term_index)
    if(contract_term[0]['criteria'][0]){
      var data = {"criteria_type": contract_term[0]['criteria'][0]['criteria_type'], "value_from": '', "value_to": '', "venue_share": '', "sporthood_share": ''}
    }
    else{
      var data = {"criteria_type": criteriaType, "value_from": '', "value_to": '', "venue_share": '', "sporthood_share": ''}
    }

    contract_term[0]['criteria'].push(data)

    setContractTerms([...contractTerms])
    
  }

  const onChangeCriteria = (e) => {
    setCriteria(e.target.value);
    // addNewCriteria(index)
  };


  const forContract = async () => {
    
    // const contract_file = new FormData();
    // contract_file.append('Contract',fileList)
    await postData('/finance/details_for_new_contract_terms/',{id:id, contract_terms: contractTerms})
    .then(response =>{
      setVenueData(response.result)
      setSelectedVenueId(response.result[0].venue.id)
    })
    .catch(error => {
      alert(error.message);
    });
  }
  const handleSubmitContract=()=>{
    setAddstatus(true)
     postData('/finance/create_new_contract_terms/',{id:id, contract_terms: contractTerms,is_technical_fee_calculated_before_share:technicalFee,
      is_technical_fee_flat_percent:flatPercent
     })
      .then(response =>{
         successModal(response.result)

      })
  }
return(
  <>

            <Styled.MainContainer>
        <span></span>
        <Styled.CenterContainer>
            <Styled.Header>
              <a style={{cursor: 'pointer'}} onClick={()=>navigate('/contractInfo')}><FaAngleDoubleLeft/> Back</a>
              <a> Add Contract Terms</a>
              <a></a>
            </Styled.Header>
        <Styled.Body>
                <div className="steps-content">

                  <div style={{marginBottom: '20px', display: 'flex', justifyContent: 'flex-end'}}>
   
                      <Button onClick={()=>addNewTerm()}> <PlusCircleOutlined /> Add </Button>
                  </div>

                  {
                    contractTerms.length == 0 ?
                      <Empty description = {<span style={{color: 'grey'}}>  Click Add to proceed </span>} />
                    :
                  
                  contractTerms.map((item,index)=>(
                    <Collapse accordion key = {index}>
                    <Panel header = {"Term " + (index+1)} key={index} extra={genExtra(index,'term')}>
                      <Row>
                        <Col span={8}>
                          <Text type="primary"> Program </Text>
                        </Col>
                        <Col span={8}>

                          <Select
                            allowClear
                            style={{
                              width: 400,
                            }}
                            placeholder="" 
                            onChange={(input,option)=>handleChangeProgram(index,option.value)}
                          >
                            {
                              filteredPrograms.map((item,index)=>(
                                <Option value={item.id} key={index}>{item.name}</Option>
                              ))
                            }
                          </Select>

                        </Col>
                      </Row>

                      <Row style={{marginTop: '25px'}}>
                        <Col span={8}>
                          <Text type="primary"> Type </Text>
                        </Col>
                        <Col span={8}>
                          <Radio.Group onChange={(e)=>onChangeMargin(e,index)} value={margin} name = 'type'>
                            <Radio value={0}> Revenue </Radio>
                            <Radio value={1}> Margin </Radio>
                          </Radio.Group>
                          <Popover content = "Margin refers revenue - expense">
                            <InfoCircleTwoTone  style={{marginLeft: '10px', cursor: 'pointer'}}/>
                          </Popover>
                        </Col>
                      </Row>

                      <Row style={{marginTop: '25px'}}>
                        <Col span={8}>
                          <Text type="primary"> Any Additional Criteria ? </Text>
                        </Col>
                        <Col span={8}>
                          <Radio.Group onChange={onChangeCriteria} value={criteria}>
                            <Radio value={0}> Yes </Radio>
                            <Radio value={1}> No </Radio>
                          </Radio.Group>
                        </Col>
                      </Row>
                      {filteredPrograms.map((item)=>(item.id == 8 && (
                         filteredData==8 &&(
                                      <>
                       <Row style={{marginTop: '25px'}}>
                        <Col span={8}>
                          <Text type="primary"> Is Technical Fee Calculated Before Share ? </Text>
                        </Col>
                        <Col span={8}>
                          <Radio.Group onChange={onChangeTechnicalFee} value={technicalFee}>
                            <Radio value={true}> Yes </Radio>
                            <Radio value={false}> No </Radio>
                          </Radio.Group>
                        </Col>
                      </Row>
                       <Row style={{marginTop: '25px'}}>
                        <Col span={8}>
                          <Text type="primary"> Is Technical Fee Flat Percent ? </Text>
                        </Col>
                        <Col span={8}>
                          <Radio.Group onChange={onChangeFlatPercent} value={flatPercent}>
                            <Radio value={true}> Yes </Radio>
                            <Radio value={false}> No </Radio>
                          </Radio.Group>
                        </Col>
                      </Row></>))))
                                       
                        }
                      {
                        criteria == 1 &&
                        <div>
                        <Row style={{marginTop: '25px'}}>
                              <Col span={8}>
                                <Text type="primary"> Venue Share (in percentage) </Text>
                              </Col>
                              <Col span={8}>
                                <InputNumber min={1} onChange={(input,option)=>onChangeVenueShare(index,input, 'term')} />
                              </Col>
                            </Row>

                            <Row style={{marginTop: '25px'}}>
                              <Col span={8}>
                                <Text type="primary"> Sporthood Share (in percentage) </Text>
                              </Col>
                              <Col span={8}>
                                <InputNumber min={1} onChange={(input,option)=>onChangeShShare(index,input, 'term')} />
                              </Col>
                            </Row>
                        </div>
                      }
                            

                      
                        
                        <div style={{margin: '20px'}}>
                          {
                            criteria ==  0 &&

                            <div style={{marginBottom: '20px', display: 'flex', justifyContent: 'flex-end'}}>
                              <Alert message="Click Add for new criteria" type="info" style={{width: '20%', height: '30px'}}/>
                              <Button style={{height: '30px', backgroundColor: '#f1e6fe', color: 'mediumvioletred'}} onClick={()=>addNewCriteria(index)}> <PlusCircleOutlined /> Add</Button>
                            </div>
                          }

                          {
                            criteria ==  0 &&
                            item.criteria.length > 0 &&
                              item.criteria.map((criteria,i)=>(
                                <Collapse accordion key={i}>
                                  <Panel header={"Criteria " + (i+1)} key={i} extra={genExtra(i,'criteria',index)}>
                                    
                                    <Row>
                                      <Col span={8}>
                                        <Text type="primary"> Criteria Type </Text>
                                      </Col>
                                      <Col span={8}>
                                        <Radio.Group onChange={(e)=>onChangeCriteriaType(e.target.value, i, index)} value={criteriaType}>
                                          <Radio value={0}> No of Kids </Radio>
                                          <Radio value={1}> Revenue Slab </Radio>
                                        </Radio.Group>
                                      </Col>
                                    </Row>

                                    <Row style={{marginTop: '25px'}}>
                                      <Col span={8}>
                                        <Text type="primary"> Value From </Text>
                                      </Col>
                                      <Col span={8}>
                                        <InputNumber min={0} onChange={(input,option)=>onChangeValueFrom(i, index, input)} />
                                      </Col>
                                    </Row>

                                    <Row style={{marginTop: '25px'}}>
                                      <Col span={8}>
                                        <Text type="primary"> Value To </Text>
                                      </Col>
                                      <Col span={8}>
                                        <InputNumber min={0} onChange={(input,option)=>onChangeValueTo(i, index, input)} />
                                      </Col>
                                    </Row>
                                     

                                    <Row style={{marginTop: '25px'}}>
                                      <Col span={8}>
                                        <Text type="primary"> Venue Share (in percentage) </Text>
                                      </Col>
                                      <Col span={8}>
                                        <InputNumber min={1} onChange={(input,option)=>onChangeVenueShare(index,input, 'criteria', i)} />
                                      </Col>
                                    </Row>

                                    <Row style={{marginTop: '25px'}}>
                                      <Col span={8}>
                                        <Text type="primary"> Sporthood Share (in percentage) </Text>
                                      </Col>
                                      <Col span={8}>
                                        <InputNumber min={1} onChange={(input,option)=>onChangeShShare(index,input, 'criteria', i)} />
                                      </Col>
                                    </Row>

                                  </Panel>

                                  
                                </Collapse>
                              ))
                          }

                          

                        </div>
                      
                    </Panel>

                  </Collapse>
                  ))

                }

                  

                </div>
                {addStatus &&
                <Styled.Button onClick={()=>handleSubmitContract()}>Submit</Styled.Button>}
                </Styled.Body>
                </Styled.CenterContainer>
                </Styled.MainContainer>
            
                </>
                )
}