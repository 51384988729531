import React, {useState, useEffect} from "react";
import moment from 'moment';
import { CheckOutlined, CloseOutlined, InfoCircleTwoTone, SaveTwoTone } from '@ant-design/icons';
import { Switch, Row, Col, Typography, Select, DatePicker, Space, Segmented, Input, Popover, Button, Modal } from 'antd';

import {
  defaultOptions,
  checkStatus,
  parseJSON,
  getLocationOrigin
}                     from '../../config/api-connect';

import * as Styled from './style.js';
import { postData,getData } from '../../config/api-connect';

const { Text, Link } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const successModal = (message) => {
    Modal.success({
      title: message,
      // onOk: ()=>navigate('/contractInfo')
      // content: 'some messages...some messages...',
    });
    
  };

const errorModal = (message) => {
    Modal.error({
      title: message,
      // onOk: ()=>navigate('/contractInfo')
      // content: 'some messages...some messages...',
    });
    
  };

export default function AddExpense(){

  const [isCentralExpense, setIsCentralExpense] = useState(false)
  const [yearMonth, setYearMonth] = useState()
  const [expenseType, setExpenseType] = useState('Coach Expense');
  const [amount, setAmount] = useState()
  const [receivedVenues, setReceivedVenues] = useState([])
  const [selectedVenueId, setSelectedVenueId] = useState('')
  const [selectedBuddyId, setSelectedBuddyId] = useState('')
  const [expenseTitle, setExpenseTitle] = useState('')
  const [file, setFile] = useState([])
  const [remarks, setRemarks] = useState('')

  useEffect(() => {
     getDetailsForExpenseAdding()
  }, []);

  const onChangeCentralExpenseCheck = (checked) => {
    setIsCentralExpense(checked)
    setSelectedVenueId('')
    setSelectedBuddyId('')
    if(isCentralExpense == true){
      setExpenseType('Central Expense')
    }
    else{
      setExpenseType('Coach Expense')
    }
  }

  const onChangeYearMonth = (date, dateString) => {
    setYearMonth(dateString)
  }

  const onChangeAmount = (e) => {
    setAmount(e.target.value)
  }

  const onChangeExpenseTitle  = (e) => {
    setExpenseTitle(e.target.value)
  }

  const onChangeFile = (e) => {
    setFile(e.target.files[0])
  }

  const onChangeRemarks = (e) => {
    setRemarks(e.target.value)
  }

  const getDetailsForExpenseAdding = async () => {
    await getData('/finance/get_details_for_add_expense/')
    .then(response =>{
      setReceivedVenues(response.result)
    })
    .catch(error => {
      errorModal(error.message);
    });
  }

  // const saveExpense = async () => {
  //   const expenseData = new FormData()
  //   expenseData.append('file', file, 'file.pdf')
  //   expenseData.append('venue_id', selectedVenueId)
  //   expenseData.append('year_and_month', yearMonth)
  //   expenseData.append('amount', amount)
  //   expenseData.append('buddy_id', selectedBuddyId)
  //   expenseData.append('expense_title', expenseTitle)
  //   expenseData.append('remarks', remarks)

  //   await postData('/finance/create_expense/',{data: expenseData})
  //   .then(response =>{
  //     alert(response.message)
  //   })
  //   .catch(error => {
  //     alert(error.message);
  //   });
  // }

     const saveExpense = () => {

      var expense_type = 2

      if(isCentralExpense){
        expense_type = 1
      }

      const url = `${getLocationOrigin()}/finance/create_expense/`;
                  const options = {...defaultOptions};

                  const expenseData = new FormData()
                  expenseData.append('file', file,)
                  expenseData.append('venue_id', selectedVenueId)
                  expenseData.append('year_and_month', yearMonth)
                  expenseData.append('amount', amount)
                  expenseData.append('buddy_id', selectedBuddyId)
                  expenseData.append('expense_title', expenseTitle)
                  expenseData.append('remarks', remarks)
                  expenseData.append('expense_type', expense_type)
                  

                  
                  options['method'] = 'POST';   
                  options['body'] = expenseData;             
      return fetch(url, options)
                  .then(checkStatus)
                  .then(parseJSON)
                  .then(data => {
                    successModal(data.result)
                  })
                  .catch(error => {
                    errorModal(error.message)

            });
    }

  


  return(
  		<Styled.MainContainer>

        <span></span>

        <Styled.CenterContainer>
          
          <Styled.Header>
            Add New Expense
          </Styled.Header>

          <Styled.Body>

            <Row>
              <Col span={8}>
                <Text type="primary"> Is Central Expense </Text>
              </Col>
              <Col span={8}>
                <Switch checkedChildren="yes" unCheckedChildren="no" onChange={onChangeCentralExpenseCheck} />
              </Col>
            </Row>

            {
              !isCentralExpense &&
              <Row style={{marginTop: '25px'}}>
                <Col span={8}>
                  <Text type="primary"> Choose Venue </Text>
                </Col>
                <Col span={8}>
                  <Select
                    allowClear
                    showSearch
                    style={{
                      width: 400,
                    }}
                    optionFilterProp="children"
                    placeholder="start typing..." 
                    onChange={(input,option)=>setSelectedVenueId(option.value)}
                    value={selectedVenueId}
                    filterOption={(input, option) => option.children.includes(input)}
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {
                      receivedVenues.map((item,index)=>(
                        <Option value={item.id} key = {index}>{item.name}</Option>    
                      ))
                    }
                  </Select>
                </Col>
              </Row>
            }

            <Row style={{marginTop: '25px'}}>
              <Col span={8}>
                <Text type="primary"> Year & Month</Text>
              </Col>
              <Col span={8}>
                <DatePicker defaultValue={yearMonth} onChange={onChangeYearMonth} picker="month" style={{width: 400}} />
              </Col>
            </Row>

            <Row style={{marginTop: '25px'}}>
              <Col span={8}>
                <Text type="primary"> Amount </Text>
              </Col>
              <Col span={8}>
                <Input placeholder="" style={{width: 400}} onChange = {onChangeAmount} value = {amount}/>
              </Col>
            </Row>

            {
              !isCentralExpense &&

              <Row style={{marginTop: '25px'}}>
                <Col span={8}>
                  <Text type="primary"> Buddy </Text>
                  <Popover content = "Buddy who is responsible for this expense">
                    <InfoCircleTwoTone  style={{marginLeft: '10px', cursor: 'pointer'}}/>
                  </Popover>
                </Col>
                <Col span={14}>
                  <Select
                    allowClear
                    showSearch
                    style={{
                      width: 400,
                    }}
                    optionFilterProp="children"
                    placeholder="start typing buddy name..." 
                    onChange={(input,option)=>setSelectedBuddyId(option.value)}
                    value={selectedBuddyId}
                    filterOption={(input, option) => option.children.includes(input)}
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {
                      receivedVenues.map(item=>(
                        item.id == selectedVenueId &&
                          item.tagged_buddies.map((buddy,index)=>(
                            <Option value={buddy.id} key={index}>{buddy.name}</Option>
                          ))
                      ))
                    }

                  </Select>
                        
                  
                </Col>
              </Row>
            }

            <Row style={{marginTop: '25px'}}>
              <Col span={8}>
                <Text type="primary"> Expense Title </Text>
                <Popover content = "Specify what the expense is">
                    <InfoCircleTwoTone  style={{marginLeft: '10px', cursor: 'pointer'}}/>
                  </Popover>
              </Col>
              <Col span={8}>
                <Input placeholder="" style={{width: 400}} onChange = {onChangeExpenseTitle} value = {expenseTitle}/>
              </Col>
            </Row>

            <Row style={{marginTop: '25px'}}>
              <Col span={8}>
                <Text type="primary"> Document/Bill </Text>
                <Popover content = "Relevant document/bill about this expense">
                    <InfoCircleTwoTone  style={{marginLeft: '10px', cursor: 'pointer'}}/>
                  </Popover>
              </Col>
              <Col span={8}>
                <input type = "file" onChange={onChangeFile} />
              </Col>
            </Row>

            <Row style={{marginTop: '25px'}}>
              <Col span={8}>
                <Text type="primary"> Remarks (if any) </Text>
              </Col>
              <Col span={8}>
                <TextArea rows={4} value={remarks} onChange={onChangeRemarks} showCount maxLength={100} placeholder="type here..."  style={{width: 400}}/>
              </Col>
            </Row>

            <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px'}}>
              <Button onClick={()=>saveExpense()} type="primary" shape="round" icon={<SaveTwoTone />} style={{width: '70%'}}> Save </Button>
            </Row>

          </Styled.Body>

        </Styled.CenterContainer>

        <span></span>

      </Styled.MainContainer>
    )
}

