import styled from 'styled-components'

export const MainContainer = styled.div`
  background-color: whitesmoke;
  min-height: 100vh;
  padding: 10px;
  position: relative;
`;

export const MainHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const DropdownContainer = styled.div`
	padding: 5px;
	background-color: aliceblue;
	border-radius: 5px;
`;

export const DropdownSelector = styled.a`
	display: flex;
  gap: 10px;
  align-items: center;
  color: black;
`;

export const DataStatsContainer = styled.div`
	margin-top: 15px;
	display: grid;
	grid-template-columns: 50% 5% 40%;
`;

export const PieDiv = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
	margin-top: 1rem;
`;

export const PieDivInfo = styled.div`
	
`;

export const TotalAmount = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export const InfoRow = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 15px;
	
`;

export const InfoColor = styled.span`
	width: 15px;
	height: 15px;
	margin-right: 10px;
`;

export const InfoLabel = styled.div`
	font-size: 13px;
`;

export const DataStatsCard = styled.div`
	background-color: white;
	border: 2px solid whitesmoke;
	padding: 10px 20px;
	border-radius: 10px;
`;


export const CalenderDate = styled.span`
	text-align: center;
	border-radius: 50%; 
	padding: 5px;
	border: 1px solid transparent;

	&: hover {
		border: 1px solid blue;
	}
`;


export const MoreDrawerFirstDiv = styled.div`
	gap: 30px;
	display: grid;
	grid-template-columns: 40% 60%;
`;

export const MoreDrawerContents = styled.div`
	gap: 30px;
	display: grid;
	grid-template-columns: 40% 60%;
	margin-top: 10px;
`;


export const PaymentStatsDiv = styled.div`
	background-color: white;
	border: 2px solid whitesmoke;
	padding: 10px 20px;
	border-radius: 10px;
	display: flex;
	align-items: flex-start;
	gap: 40px;
	margin-top: 20px;
	width: fit-content;
`;






