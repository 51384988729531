import React, {useState, useEffect} from "react";
import { Select, DatePicker, Spin, Empty, Drawer, Modal ,Tooltip,Menu,Dropdown,Input,Popconfirm,message} from 'antd';
import moment from 'moment';
import ReactCardFlip from 'react-card-flip'
import { ArrowLeftOutlined, CompassOutlined, CloseCircleOutlined,  MoreOutlined} from '@ant-design/icons';

import * as Styled from './style.js';
import User from '../../assets/images/user.jpeg'
import { postData,getData } from '../../config/api-connect';
import Spinner from '../../components/spinner'


import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Container, Link, Button } from 'react-floating-action-button'
import { BsFillEyeFill } from "react-icons/bs"
import { TbFileDescription } from "react-icons/tb";

import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const { Option } = Select;
const { TextArea } = Input;


const errorModal = (message) => {
  Modal.error({
    title: 'Oops! Something went wrong',
    content: message,
  });
};

export default function SalaryDetails(){

  var today = new Date();
  var mm = JSON.parse(localStorage.getItem('month'));
  var yyyy = today.getFullYear()
  yyyy = yyyy.toString()
  



  const [cities, setCities] = useState([])

  const [selectedId, setSelectedId] = useState('')
  const [month, setMonth] = useState(mm)
  const [year, setYear] = useState(yyyy)
  const [data, setData] = useState([])
  const [isFlipped, setIsFlipped] = useState(-1)
  const [loading, setLoading] = useState(true)
  const [incentive,setIncentive] = useState([])
  const [open, setOpen] = useState(false)
  const [show, setShow] = useState(false);
  const [buddyVenue,setBuddyVenue]=useState([])
  const [editId,setEditId] = useState()
  const [editAmount,setEditAmount] = useState()
  const [editDescription,setEditDescription] = useState()
  const [editAmountStatus,setEditAmountStatus] = useState(false)
  const [editDescriptionStatus,setEditDescriptionStatus] = useState(false)
  const [editedAmount,setEditedAmounted] = useState()
  const [editedDescription,setEditedDescription] = useState()
  const [updatedBy,setUpdatedBy] = useState()
  const [updatedOn,setUpdatedOn] = useState()
  const [downloadData,setDownloadData] = useState([])
  const [isDownloadOpen,setIsDownloadOpen] = useState(false)
  const [allCity,setAllCity] = useState([])

  const [isOpen,setIsOpen] = useState(false)
  const [isSalaryModalOpen,setIsSalaryModalOpen] = useState(false)

    const city = JSON.parse(localStorage.getItem('selectedId'))
    const type = JSON.parse(localStorage.getItem('type'))

  useEffect(() => {
     getCitiesVenues()
     downloadSalaryDetailsofAllCity(
      )
    
  }, []);


  useEffect(() => {
     localStorage.setItem('month', JSON.stringify(month));
     localStorage.setItem('year', JSON.stringify(year));
     localStorage.setItem('selectedId', JSON.stringify(selectedId));
     localStorage.setItem('type', JSON.stringify("1"));
     

  }, [month,year,selectedId,type]);



  useEffect(() => {
    if(selectedId != ''){
      getBuddyMonthlyPayments()
    }
  }, [selectedId, year, month]);

  useEffect(() => {
    if(open){
     getIncentiveRules()
    }
  }, [open]);

const successModal = (message) => {
    Modal.success({
      title: message,
      
    });
    
  };
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const menu = (
  <Menu>
    <Menu.Item>
      <a onClick={()=>salaryEdit()}>Edit</a>
    </Menu.Item>
    
  </Menu>
);

  const getCitiesVenues = async () => {
    setLoading(true)
    await getData('/finance/get_cities_and_venues')
    .then(response =>{
      setCities(response.cities)
     
      if(city != 'All' && type ==1){
        for(var i =0;i<city.length; i++){
       if( cities[i]['id']== city){
        setSelectedId(city)
      }
     }
        setSelectedId(city)
      }
      else {
      setSelectedId(response.cities[0].id)
    }
       setLoading(false)
    })
    .catch(error => {
      errorModal(error.message)
      setLoading(false)
    });
  }
  const [isModalOpen, setIsModalOpen] = useState(false);
    
      const handleOk = () => {
        // setIsModalOpen(false);
        
      };
    
      const handleCancel = () => {
        setIsModalOpen(false);
        setIsOpen(false)
        setIsSalaryModalOpen(false)
        setEditAmount()
        setEditAmountStatus(false)
        setEditDescription()
        setEditDescriptionStatus(false)
        setIsDownloadOpen(false)
      };
const getid=(id)=>{

  setEditId(id)
  
}

const salaryEdit =()=>{

  setIsOpen(true)
}
const edit_amount=(e)=>{
  setEditAmount(e.target.value)
  setEditAmountStatus(false)
}
const edit_description=(e)=>{
  setEditDescription(e.target.value)
   setEditDescriptionStatus(false)
}
  
const editSave =()=>{
    if (editAmount =='' || editAmount == undefined){
      setEditAmountStatus(true)
    }
    else if (editDescription =='' || editDescription == undefined){
      setEditDescriptionStatus(true)
    }
    else{
    postData('/finance/salary_edit/',{
      id:editId,
      amount:editAmount,
      description:editDescription
    })
    .then(response =>{
     successModal(response.result)
     setLoading(true)
     setEditAmount()
     setEditAmountStatus(false)
     setEditDescription()
     setEditDescriptionStatus(false)
   })
    .catch(error => {
    alert(error.message);
   });
    getBuddyMonthlyPayments()
   // setIsOpen(false)
   
  }

}
const salary_edit_detail=(item)=>{
  setEditedAmounted(item.others)
  setEditedDescription(item.description)
  setUpdatedBy(item.updatedby)
  setUpdatedOn(item.updated_on)
  setIsSalaryModalOpen(true)

}
  const getBuddyMonthlyPayments = async () => {
      setIsOpen(false)
    setLoading(true)
    await postData('/finance/get_buddy_monthly_payments/', {'city_id': selectedId, "year": year, "month": month})
    .then(response =>{
      setData(response.result)
      setLoading(false)

    })
    .catch(error => {
      errorModal(error.message);
      setLoading(false)
    });

  }

  const onChangeYear = (date, dateString) => {
    setYear(dateString);
  };


  const getIncentiveRules = async () => {
    await getData('/finance/get_incentive_rules')
    .then(response =>{
      setIncentive(response.result)
      
    })
    .catch(error => {
      errorModal(error.message)
    });
  }
const getVenue=(item)=>{
    setIsModalOpen(true);
     setBuddyVenue(item)
    

}
const downloadDetails=()=>{
  setIsDownloadOpen(true)
  downloadSalaryDetails (selectedId)
}
const downloadSalaryDetails = (id) => {
  postData('/finance/download_salary_details/',{'id':id,'month':month,'year':year})
  .then(response=>{
    setDownloadData(response.result)
  })
 
};
const downloadSalaryDetailsofAllCity = () => {
  postData('/finance/download_salary_details_all_city/',{'month':month,'year':year})
  .then(response=>{
    setAllCity(response.result)
  })
 
};
const cancel = (id) => {
  
  message.error(id);
};

  return(
    <Spin spinning={loading} indicator={<Spinner />}>
    <Styled.MainContainer>

      <Drawer title="Incentive Rules" placement="right" onClose={onClose} visible={open} closeIcon = {<CloseCircleOutlined style={{color: 'crimson', fontSize: 'larger'}} />} headerStyle={{backgroundColor: '#052e67'}}>
        <div>
            {
              incentive.map((item,i)=>(
                  <div key = {i}>
                    {
                      item.key.includes('bangalore') ?
                        <div style={{fontWeight: 'bold', }}> Bangalore </div>
                      :
                       item.key.includes('cm')?
                        <div style={{fontWeight: 'bold', marginTop: '20px'}}> Kerala - CM + Coach </div>
                      :
                        <div style={{fontWeight: 'bold', marginTop: '20px'}}> Kerala - Coach </div>
                    }

                    {
                      item.rules.terms.map((term,index)=>(
                        <div key = {index}>
                          From {term.from} to {term.to} : {term.amount} per kid
                        </div>
                    ))
                    }
                  </div>
              ))
            }
        </div>
      </Drawer>

      <Styled.TopHeader>
        <Styled.Selector>
          <Select
                style={{ width: 170 }}
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) => option.name.toLowerCase().includes(input.toLowerCase())}
                onChange={setSelectedId}
                value = {selectedId}
                bordered={false}
              >

              {
                cities.map(item=>(
                  <Option value={item.id} key={item.id} name = {item.name} > {item.name} </Option>    
                ))
              }

              </Select>
        </Styled.Selector>

        <div style={{display: 'flex'}}>
            <Styled.Selector style={{marginRight: '10px'}}>
              <Select
                  style={{
                    width: 'auto',
                  }}
                  onChange={setMonth}
                  value = {month}
                  bordered={false}
                >
                  <Option value="12"> December </Option>
                  <Option value="11"> November </Option>
                  <Option value="10"> October </Option>
                  <Option value="09"> September </Option>
                  <Option value="08"> August </Option>
                  <Option value="07"> July </Option>
                  <Option value="06"> June </Option>
                  <Option value="05"> May </Option>
                  <Option value="04"> April </Option>
                  <Option value="03"> March </Option>
                  <Option value="02"> February </Option>
                  <Option value="01"> January </Option>
                </Select>
            </Styled.Selector>

            <Styled.Selector>
              <DatePicker defaultValue={moment(year, 'YYYY')}  onChange = {onChangeYear}  bordered={false}  picker="year" />
            </Styled.Selector>
          </div>



      </Styled.TopHeader>

      <Styled.DownloadBottom>
        <Styled.DownloadBottomButton onClick={downloadDetails}>Download Details</Styled.DownloadBottomButton>
      </Styled.DownloadBottom>

      <Styled.BodyContainer>

        {
          data.length > 0 ?
          data.map((item,index)=>(
            <ReactCardFlip isFlipped={isFlipped == index ? true : false}>
            <Styled.BuddyCard key={index}>
               <div style={{display: 'flex', alignItems: 'flex-end', flexDirection: 'column', width: '100%' ,marginTop:'-9px'}}>
                  <Dropdown overlay={menu} trigger={['click']}>
                    
                     <MoreOutlined onClick={()=>{getid(item.id)}}/>
                    
                  </Dropdown>
                </div>
              <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%'}}>
                <img src = {item.buddy_photo}  style={{width: '100px',height: '100px', borderRadius: '50%'}}/>
                <div style = {{marginTop: '5px', fontWeight: 'bold', fontSize: 'medium'}}> {item.buddy_name} </div>
                <div style={{color: 'grey', fontSize: 'smaller'}}> {item.buddy_type} </div>
              </div>

              <div style={{marginTop: '10px'}}>
                <div style={{fontSize: 'small', textAlign: 'center', borderRadius: '5px', backgroundColor: 'antiquewhite'}}>
                {
                  item.venues &&
                  item.venues.map((venue,index)=>(
                      index < 2 && 
                      <div>
                            {venue.name.length>20 ? 
                              <span>{ venue.name.substring(0, 22)}...</span>
                            :
                              <span>{venue.name}</span> }
                      </div>
                  ))
                }
                  {
                    item.venues.length > 3 && <Tooltip title="View More" ><BsFillEyeFill size={"1em"} style={{cursor:'pointer'}} onClick={()=>getVenue(item.venues)}/></Tooltip>
                  }

                </div>
                {/*<div style={{fontSize: 'small', display: 'grid', gridTemplateColumns: '40% 60%'}}> 
                                  <div> Venue : </div>
                                    {
                                      item.venues &&
                                      item.venues.map((venue,index)=>(
                                        <>
                                          { index > 0 && <span></span>}
                                          <div>{venue.name} </div>
                                        </>
                                      ))
                                    }
                                </div>*/}
                
                <div style={{marginTop: '15px'}}>
                  <div style={{fontSize: 'small', display: 'grid', gridTemplateColumns: '50% 10% 60%'}}>
                    <span>Basic Pay </span>
                    <span> : </span>
                    <span> {item.basic_pay} </span>
                  </div>
                  <div style={{fontSize: 'small', display: 'grid', gridTemplateColumns: '50% 10% 60%'}}>
                    <span>Incentives </span>
                    <span> : </span>
                    <span> {item.incentive} </span>
                  </div>
                  <div style={{fontSize: 'small', display: 'grid', gridTemplateColumns: '50% 10% 60%'}}>
                    <span>Reimbursement </span>
                    <span> : </span>
                    <span> {item.reimbursement} </span>
                  </div>
                  <div style={{fontSize: 'small', display: 'grid', gridTemplateColumns: '50% 10% 60%'}}>
                    <span>Others </span>
                    <span> : </span>
                    <span> {item.others}{item.description && <Tooltip title="Description"><TbFileDescription onClick={()=>salary_edit_detail(item)} style={{cursor:'pointer'}}/></Tooltip>}</span>
                  </div>
                </div>

                <div style={{fontSize: 'small', display: 'grid', gridTemplateColumns: '50% 10% 60%', marginTop: '10px', color: 'green', fontWeight: 'bold'}}>
                    <span>Total </span>
                    <span> : </span>
                    <span> {item.basic_pay + item.incentive + item.reimbursement + item.others} </span>
                  </div>

              </div>  

              <div style={{textAlign: 'center', position: 'absolute', bottom: 15, left: 15, right: 15}} onClick={()=>setIsFlipped(index)}>
                <div style={{width: '100%', marginTop: '15px', padding: '1px', borderRadius: '5px', backgroundColor: 'whitesmoke', textAlign: 'center', cursor: 'pointer'}}>
                  View Details
                </div>
                
              </div>

            </Styled.BuddyCard>

            <Styled.BuddyCard>
              <div style={{backgroundColor: '#052e67', padding: '5px', borderRadius: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <ArrowLeftOutlined  onClick={()=>setIsFlipped(-1)} style={{color: 'white', cursor: 'pointer'}} />
                <div style={{color: 'white', fontWeight: 'bold'}}> FCE Data </div>
                <span></span>
              </div>

              <div style={{marginTop: '10px'}}>
                
                {
                  item.fce.map(data=>(
                    <div>
                      <div style={{backgroundColor: 'antiquewhite', borderRadius: '5px', padding: '3px'}}> {data.venue} </div>
                      {
                        data.data.map(i=>(
                          <div style={{fontSize: 'small', display: 'grid', gridTemplateColumns: '75% 5% 20%', padding: '3px 10px'}}>
                            <span> {i.program} </span>
                            <span> : </span>
                            <span> {i.fce_count} </span>
                          </div>
                        ))
                      }
                    </div>
                  ))
                }

              </div>

            </Styled.BuddyCard>
            </ReactCardFlip>
          ))
        :
          <div style={{marginTop: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
            <Empty description = {<span style={{color: 'grey', fontSize: 'small'}}> data not available </span>} />
          </div>
        }

        

      </Styled.BodyContainer>

    </Styled.MainContainer>
     

    <Container>
            
            <Button
              tooltip="View incentive rules"
              rotate={true}
              onClick={showDrawer}
              styles={{backgroundColor: '#052e67', position: 'fixed', bottom: 20, right: 20, height: '45px', width: '45px'}} >
                <CompassOutlined style={{color: 'yellow', fontSize: '20px'}} />
            </Button>
        </Container>


 <Modal title="Venue Details " visible={isModalOpen}  onCancel={handleCancel}  okButtonProps={{ style: { display: 'none' } }}

>
    {buddyVenue.map((items)=>{
          return(<div className="venue">{items.name}</div>)
        })}
</Modal>
 <Modal title="Edit Salary  Details " visible={isOpen}  onCancel={handleCancel}  onOk={()=>editSave()}>
    
      <Row>
        <Col>
          <span>Amount </span> 
        </Col>
        <Col>
          <Input type="number" value={editAmount} onChange={edit_amount}  style ={{width:'200px'}}/>
         { editAmountStatus &&<p style={{fontSize: '10px', color:'red'}}>Please fill the amount</p>}
        </Col>
        <Col></Col>
      </Row>
       <Row style ={{marginTop:'7px'}}>
        <Col>
          <span>Description </span>
        </Col>

         <Col>
           <TextArea type="text" value={editDescription} onChange={edit_description} style ={{width:'200px'}}/>
           {editDescriptionStatus && <p style={{fontSize: '10px', color:'red'}}>Cannot be Blank</p>}
        </Col>
        <Col></Col>
      </Row>
    

        
</Modal>
<Modal title="Edited Details " visible={isSalaryModalOpen}  onCancel={handleCancel}  okButtonProps={{ style: { display: 'none' } }}

> 
  <Row>
        <Col>
          <span>Amount </span> 
        </Col>
         <Col>
          <span>{editedAmount}</span>
        </Col>
      
      </Row>
       <Row style ={{marginTop:'7px'}}>
        <Col>
          <span>Description </span>
        </Col>
         <Col>
          <span>{editedDescription}</span>
        </Col>
      </Row>
      <Row style ={{marginTop:'7px'}}>
        <Col>Edited By </Col>
        <Col>{updatedBy}</Col>
      </Row>
      <Row style ={{marginTop:'7px'}}>
        <Col>Edited On </Col>
        <Col>{updatedOn}</Col>
      </Row>
</Modal>
<div>
  <Modal title="Download Salary Data" visible ={isDownloadOpen} onCancel={handleCancel} okButtonProps={{ style: { display: 'none' } }}>
      <span>Choose an Option</span>
      <div>
        <ReactHTMLTableToExcel
                              id="btn btn-lg"
                              className="collection-xls-button downloadtable"
                              table="table-to-download"

                              filename="Salary Details"
                              sheet="Salary Details"
                              buttonText="Salary Details of Current City"/>
         <ReactHTMLTableToExcel
                              id="btn btn-lg"
                              className="collection-xls-button downloadtable"
                              table="table-to-download-all-city"
                              filename="Salary Details of All City"
                              sheet="Salary Details"
                              buttonText="Salary Details of All City"/>
      </div>

  </Modal>

</div>
<table style ={{display: 'none'}} id="table-to-download">

  <thead>
    <tr>
      <th>Name</th>
      <th>Designation</th>
      <th>Center</th>
      <th>Account No.</th>
      <th>IFSC Code</th>
      <th>Fce Count</th>
      <th>Basic Salary</th>
      <th>Incentive</th>
      <th>Reimbursment</th>
      <th>Others</th>
      <th>Total</th>
      <th>Remark</th>
      

    </tr>
  </thead>
  <tbody>
   {downloadData.map((item)=>(
    <tr>
      <td>{item.buddy_name}</td>
      <td>{item.buddy_type}</td>
      <td>{item.fce.venue.map((items)=>(<div>{items.venue}</div>))}</td>
      <td>{item.account_details.account_no}</td>
      <td>{item.account_details.ifsc}</td>
      <td>{item.fce.fce_count}</td>
      <td>{item.basic_pay}</td>
      <td>{item.incentive}</td>
      <td>{item.reimbursement}</td>
      <td>{item.others}</td>
      <td>{item.basic_pay+item.incentive+item.reimbursement+item.others}</td>
      <td>{item.description}</td>


    </tr>
   ))}
  </tbody>


</table>
<table style ={{display: 'none'}} id="table-to-download-all-city">
  <thead>
    <tr>
      <th>Name</th>
      <th>Designation</th>
      <th>Center</th>
      <th>Account No.</th>
      <th>IFSC Code</th>
      <th>Fce Count</th>
      <th>Basic Salary</th>
      <th>Incentive</th>
      <th>Reimbursment</th>
      <th>Others</th>
      <th>Total</th>
      <th>Remark</th>
      

    </tr>
  </thead>
  <tbody>
   {allCity.map((item)=>(
    <tr>
      <td>{item.buddy_name}</td>
      <td>{item.buddy_type}</td>
      <td>{item.fce.venue.map((items)=>(<div>{items.venue}</div>))}</td>
      <td>{item.account_details.account_no}</td>
      <td>{item.account_details.ifsc}</td>
      <td>{item.fce.fce_count}</td>
      <td>{item.basic_pay}</td>
      <td>{item.incentive}</td>
      <td>{item.reimbursement}</td>
      <td>{item.others}</td>
      <td>{item.basic_pay+item.incentive+item.reimbursement+item.others}</td>
      <td>{item.description}</td>


    </tr>
   ))}
  </tbody>


</table>

    </Spin>
  )
}