
//below function determines whether the portal should connect to local server or production server
//for testing and development purpose, it is recommended to use local server

export const getLocationOrigin = () => {
   //local server
   // return "http://localhost:8000/api/v1";

   //production server
   return "https://api.sporthood.in/api/v1";
};


export const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } 
  else {
    const error = new Error(response.statusText);
    error.response = response;
    // throw error;
    return Promise.reject(error);
  }
};

export const parseJSON = (response) => {
  return response.json();
};

export const defaultOptions = {
  credentials: 'include'
};


export async function postData(url, data = {}) {
  var custom_url = `${getLocationOrigin() + url}`;
  const response = await fetch(custom_url, {
    method: 'POST',
    mode: 'cors', 
    cache: 'no-cache', 
    credentials: 'include', 
    headers: {
      'Content-Type': 'application/json'
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data) 
  })
  .then(checkStatus)
  .then(parseJSON)
  return response
}



export async function getData(url) {
  var custom_url = `${getLocationOrigin() + url}`;
  const options = {...defaultOptions};
  const response = await fetch(custom_url,options)
  .then(checkStatus)
  .then(parseJSON)
  return response
}