
import React,{useState,useEffect} from "react"
import {Routes, Route, useNavigate,useLocation} from 'react-router-dom';
import * as Styled from './style.js';
import { postData,getData } from '../../config/api-connect';
import moment from 'moment';
import {Table,Button,Select,DatePicker,Modal,Spin,Tooltip} from 'antd';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaTrashAlt,FaEdit } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import Spinner from '../../components/spinner'
import { FaAngleDoubleLeft } from "react-icons/fa";

const { Option } = Select;


const ExpenseDetails=()=>{
	var today = new Date();
  	var mm = String(today.getMonth() + 1).padStart(2, '0')
  	var yyyy = today.getFullYear()
  	yyyy = yyyy.toString()

   const navigate = useNavigate();
   const {state} = useLocation();

   const {type,id,months,years,venue_id}= state

  const [loading, setLoading] = useState(true)
	const [expenseData,setExpenseData]=useState()
	const [expenseType,setExpenseType]=useState()
	const [expenseId,setExpenseId]=useState(0)
	const [active, setActive] = useState(expenseId);
	const [year, setYear] = useState(yyyy)
	const [month, setMonth] = useState(mm)
	const [editStatus,setEditStatus]=useState(true)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [editId,setEditId]=useState()
	const [editAmount,setEditAmount]=useState()
	const [editExpenseTitle,setEditExpenseTitle]=useState()
	// const [type,setType]=useState(state.type)
	// const [id ,setId]=useState(state.id)


useEffect(() => {
	setLoading(true)
	getExpenseDetails()

},[active,expenseId,month,year]);

	const getExpenseDetails=()=>{
		getData(`/finance/get_expense_details/?expense_type=${expenseId}&year=${state.year}&month=${state.month}&type=${state.type}&id=${state.id}&venue_id=${state.venue_id}`)
    	.then(response =>{
      	setExpenseData(response.data)
      	setExpenseType(response.expense_types)
      	setLoading(false)

	})
	}


	const editExpense=(item)=>{
		setIsModalOpen(true)
		setEditId(item.id)
		setEditAmount(item.amount)
		setEditExpenseTitle(item.expense_title)

	}

	const renderItems=(item)=>{
		return(
			<span>
			{
				item &&
					item.name ? item.name : '-'				
				
			}
			</span>
			)
		}
		
	const columns = [
   {
    title: 'Name',
    dataIndex: 'buddy',
    render:(item)=> renderItems(item),
    key: 'name',
  },

  {
    title: 'Venue',
    dataIndex: 'venue',
    render:(item)=> renderItems(item),
    key: 'names',
  },
 {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
{
    title: 'Expense Title',
    dataIndex: 'expense_title',
    key: 'expense_title',
  },
  {
    title: 'Month and Year',
    dataIndex: 'dates',
    key: 'dates',
  },
    {
    title: 'Actions',
    dataIndex: '',
    key: '',
        render: (item,record) =><>
        	<Tooltip title="Edit"><a>{expenseId ==0?"":<BiEdit size="1.3em" onClick={()=>editExpense(record)} style={{marginRight:"10px"}}/>}</a></Tooltip>
        	<Tooltip title="Delete"><a><FaTrashAlt onClick={()=>confirmDelete(record.id)}/></a></Tooltip>	
        	
        </>

  },
 
  ]
  	const successModal = (message) => {
    Modal.success({
      title: message,
      
    });
    
  };
    const delModal = (message,id) => {
    Modal.confirm({
      title: message,
      onOk:()=>{deleteExpense(id)}
      
    });
    
   
  };
  const handleCancel = () => {
        setIsModalOpen(false);
      };

  const onChangeValue=(item)=>{
  	setExpenseId(item)
  	setActive(item)
  }
    const onChangeYear = (date, dateString) => {
  		setYear(dateString);
	};

	const confirmDelete=(id)=>{
  		delModal("Do you want to delete",id)
	}
	const confirm = (e,id) => {
      	deleteExpense(id)
    };

	const deleteExpense=(id)=>{

		postData('/finance/delete_expense/',{expense_del_id:id})
		.then(response=>{
			successModal(response.result)
      		getExpenseDetails()
		})
		.catch(error => {
      		alert(error.message);
     	});
	}

	const edit_amount=(e)=>{
		setEditAmount(e.target.value)
	}
	const edit_expense_title=(e)=>{
		setEditExpenseTitle(e.target.value)
	}
	const editSave=(id)=>{
		postData('/finance/edit_expense_details/',{ 
        expense_edit_id: id,
        amount:editAmount,
        expense_title:editExpenseTitle
        
        })
    .then(response =>{
     successModal(response.result)
     setLoading(true)
      getExpenseDetails()
    })
    .catch(error => {
      alert(error.message);
     });
    setIsModalOpen(false)
  }
	

return(
	 <Spin spinning={loading} indicator={<Spinner />}>
	<Styled.MainContainer>
        <span></span>
        <Styled.CenterContainer>
          	<Styled.Header >
          <Tooltip title="Back"><a style={{cursor: 'pointer'}} onClick={()=>navigate('/')}><FaAngleDoubleLeft/> Back</a></Tooltip>
           <a>Expense Details</a>
           <a></a>
          </Styled.Header>
    		<Styled.Body>
    		
    			<Container>
    				<Row>
    					<Col>
    						{
                		expenseType &&	Object.keys(expenseType).map((item,index)=>(
                  			<Styled.Button onClick={()=>{onChangeValue(item)}} value={item} key={item} name = {item}
                  			 className={active ==index ? "active" : "inactive"}
                  			> {expenseType[item]}
                  			 </Styled.Button>    
               			 ))
              			}
    					</Col>
    				</Row>
    			</Container>

    			<Table
				rowKey="id"
	      		columns={columns}
	      		dataSource={expenseData}
	      		pagination={{ pageSize: 20, showSizeChanger: false}}
	      	/>
    		</Styled.Body>
    	</Styled.CenterContainer>
  	</Styled.MainContainer>  

  	 <Modal  visible={isModalOpen}  onCancel={handleCancel} onOk={()=>editSave(editId)} >
  	 	
  	 	<p>Amount <span style={{marginLeft:"32px"}}> : <Styled.Input type="text" value={editAmount} onChange={edit_amount} ></Styled.Input></span></p>
  	 	<p>Expense Title <span>:  <Styled.Input type="text" value={editExpenseTitle} onChange={edit_expense_title}></Styled.Input></span></p>
  	 
    
	</Modal>
  	</Spin>  
)

}
export default ExpenseDetails