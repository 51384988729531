import styled from 'styled-components'

export const MainContainer = styled.div`
  display: grid;
  grid-template-columns:0% 100% 0%;
  padding: 30px;

`;

export const CenterContainer = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  background-color: white;
  padding: 10px;
  width: 100%;
  border-radius: 7px;
  display:flex;
  justify-content : space-between;
  font-size: large;
`;

export const Body = styled.div`
  margin-top: 10px;
  background-color: white;
  border-radius: 7px;
  padding: 15px;
`;
export const Button =styled.button`
  margin-top : 5px;
  background-color : blue;
  width:5em;

`
