import styled from 'styled-components'

export const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 15% 70% 15%;
  padding: 20px;

`;

export const CenterContainer = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  background-color: white;
  padding: 10px;
  width: 100%;
  border-radius: 7px;
  display : flex;
  justify-content : space-between;
  font-size: large;
`;

export const Body = styled.div`
  margin-top: 10px;
  background-color: white;
  border-radius: 7px;
  padding: 15px;
`;

export const OptionDiv = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const OptionLabel = styled.div`
  width: 30%;
`;

export const OptionSelect = styled.div`
  width: 70%;
`;